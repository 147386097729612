import React, { useMemo } from "react";
import { getAddonSizeData } from "./utils";

const SoldProductReportAddonTable = ({ addOnData = {} }) => {
  const { options_add_ons_report = [] } = addOnData;

  const addonSizesData = useMemo(
    () => getAddonSizeData(options_add_ons_report),
    [options_add_ons_report]
  );

  return (
    <table className="table" style={{tableLayout:'fixed'}}>
      <thead>
        <th className="name" style={{ textAlign: "left", paddingLeft: "10px",verticalAlign:"middle" }}>
          {addOnData?.add_on}
        </th>
        <th className="text-center">-</th>
        <th className="text-center" style={{verticalAlign:'middle'}}>Small</th>
        <th className="text-center" style={{verticalAlign:'middle'}}>Medium</th>
        <th className="text-center" style={{verticalAlign:'middle'}}>Large</th>
      </thead>
      <tbody>
        {addonSizesData.map((item, i) => {
          return (
            <tr key={i}>
              <td className="name" style={{verticalAlign:'middle'}}>{item.name}</td>
              <td className="text-center">{item["-"] || "0"}</td>
              <td className="text-center" style={{verticalAlign:'middle'}}>{item?.Small || "0"}</td>
              <td className="text-center" style={{verticalAlign:'middle'}}>{item?.Medium|| "0"}</td>
              <td className="text-center" style={{verticalAlign:'middle'}}>{item?.Large || "0"}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SoldProductReportAddonTable;
